import { useState, useEffect } from 'react'
import Selection from 'components/molecules/Selection'
import apiPost from 'lib/network/apiPost'
import apiGet from 'lib/network/apiGet'
import notify from 'components/atoms/Notify'
import apiPut from 'lib/network/apiPut'
import _ from 'lodash'
import Switch from 'components/atoms/Switch'

const Discount = ({ onSuccess, close, id = null }) => {
  const [type, setType] = useState('')

  const [formData, setFormData] = useState({
    cupon: '',
    amount: '',
    percentage: '',
    effectiveStartDate: '',
    effectiveEndDate: '',
    isActive: false,
  })

  useEffect(() => {
    if (id) {
      fetchDiscountData()
    }
  }, [id])

  const fetchDiscountData = async () => {
    const response = await apiGet(`/discount/${id}`)
    if (response.success) {
      const data = response.data
      setFormData({
        cupon: data.cupon || '',
        amount: data.amount || '',
        percentage: data.percentage || '',
        effectiveStartDate: formatDate(data.effectiveStartDate) || '',
        effectiveEndDate: formatDate(data.effectiveEndDate) || '',
        isActive: data.status || false,
      })

      setType(data.type || '')
    } else {
      notify.error('Failed to fetch discount data')
    }
  }
  const formatDate = isoString => {
    return isoString ? _.split(isoString, 'T')[0] : ''
  }

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSwitchChange = checked => {
    setFormData(prevData => ({
      ...prevData,
      isActive: checked,
    }))
  }

  const handleSaveDiscount = async () => {
    const { cupon, amount, percentage, effectiveStartDate, effectiveEndDate } = formData
    const payload = { type, cupon, amount, percentage, effectiveStartDate, effectiveEndDate, status: formData.isActive }

    const endpoint = id ? `discount/${id}` : 'add/discount'
    const apiMethod = id ? apiPut : apiPost
    const response = await apiMethod(endpoint, payload)

    if (response.success) {
      const message = id ? 'Discount updated successfully' : 'Discount added successfully'
      notify.success(message)
      onSuccess(response.data._id)
    } else {
      notify.error(response.message || 'Failed to save discount')
    }
  }

  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label htmlFor="type" className="block text-sm font-normal text-gray-700 mb-1">
            Discount Type
          </label>
          <Selection
            onChange={value => {
              if (value && value.id) {
                setType(prevValue => (prevValue === value.id ? '' : value.id))
              }
            }}
            title="Select Discount Type"
            list={[
              { id: 'Flat', name: 'Flat' },
              { id: 'Percentage', name: 'Percentage' },
            ]}
            value={type}
            className="w-full"
          />
        </div>

        <div>
          <label htmlFor="cupon" className="block text-sm font-normal text-gray-700 mb-1">
            Cupon Code
          </label>
          <input type="text" name="cupon" value={formData.cupon} onChange={handleChange} className="w-full" />
        </div>

        {type === 'Flat' && (
          <div>
            <label htmlFor="amount" className="block text-sm font-normal text-gray-700 mb-1">
              Amount
            </label>
            <input type="number" name="amount" value={formData.amount} onChange={handleChange} className="w-full" />
          </div>
        )}

        {type === 'Percentage' && (
          <div>
            <label htmlFor="percentage" className="block text-sm font-normal text-gray-700 mb-1">
              Percentage
            </label>
            <input type="number" name="percentage" value={formData.percentage} onChange={handleChange} className="w-full" />
          </div>
        )}

        <div>
          <label htmlFor="effectiveStartDate" className="block text-sm font-normal text-gray-700 mb-1">
            Start Date
          </label>
          <input type="date" name="effectiveStartDate" value={formData.effectiveStartDate} onChange={handleChange} className="w-full" />
        </div>

        <div>
          <label htmlFor="effectiveEndDate" className="block text-sm font-normal text-gray-700 mb-1">
            Expire Date
          </label>
          <input type="date" name="effectiveEndDate" value={formData.effectiveEndDate} onChange={handleChange} className="w-full" />
        </div>

        <div className="flex items-center space-x-4 pl-5">
          <p className="whitespace-nowrap">Status</p>
          <Switch
            id="status"
            value={formData.isActive}
            onChange={checked => {
              setFormData(prev => ({
                ...prev,
                isActive: checked,
              }))
            }}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm rounded-md text-white bg-dark-600 hover:bg-dark-700"
          onClick={handleSaveDiscount}
        >
          {id ? 'Update Discount' : 'Add Discount'}
        </button>

        <button type="button" className="ml-2 inline-flex justify-center rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200" onClick={close}>
          Close
        </button>
      </div>
    </div>
  )
}

export default Discount
