import CsvDropzone from 'components/atoms/CsvDropzone'
import { CloudUploadIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import { DownloadIcon } from '@heroicons/react/outline'
import { isArray } from 'lodash'

const ImportUsers = ({ close, refetch, companyNames }) => {
  const [file, setFile] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [errorMessages, setErrorMessages] = useState({})

  function onDrop(file) {
    if (!file || !file.type === 'application/zip') return
    setFile(file)
  }

  const saveImportedCsv = async () => {
    if (!file) {
      return null
    } else {
      setUploading(true)

      const formData = new FormData()
      formData.append('carZip', file)
      formData.append('companyId', selectedCompany)

      const response = await apiPost('import-cars', formData)
      if (response.success) {
        close()
        notify.success('Import Users', 'CSV Imported successfully')
        refetch()
      } else {
        try {
          const errors = JSON.parse(response.err)
          setErrorMessages(errors)
        } catch (error) {}
      }
      setUploading(false)
    }
  }

  return (
    <>
      <div className="flex justify-between gap-2">
        <Selection
          value={selectedCompany}
          onChange={e => {
            setSelectedCompany(e.id)
          }}
          className="w-full mb-3"
          list={companyNames}
          title="Select Company"
        />
        <a
          href="/sample.zip"
          className="mb-3 flex-nowrap whitespace-nowrap items-center flex justify-end px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white hover:text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
        >
          <DownloadIcon className="h-5 w-5 mr-3" aria-hidden="true" />
          Download Sample Zip
        </a>
      </div>

      <CsvDropzone onDrop={onDrop} setFile={setFile} setErrorMessages={setErrorMessages} errorMessages={errorMessages} />
      {isArray(errorMessages) &&
        errorMessages?.map((error, index) => {
          return (
            <p className="text-xs flex text-red-600 gap-2 mt-1 overflow-hidden" key={index}>
              <b>{error?.sheet}:</b>
              {error?.error}
            </p>
          )
        })}
      <div className="mt-4 flex justify-end">
        <button
          onClick={() => close()}
          className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
        >
          Close
        </button>
        <button
          disabled={uploading || !file || !selectedCompany}
          onClick={() => saveImportedCsv()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
        >
          {!uploading ? (
            <CloudUploadIcon className="h-5 w-5 mr-3" aria-hidden="true" />
          ) : (
            <svg className="animate-spin h-5 w-5 mr-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
          Upload
        </button>
      </div>
    </>
  )
}

export default ImportUsers
