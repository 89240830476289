import { useState } from 'react'
import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'

const AddUser = ({ onSuccess, close }) => {
  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')

  const handleAddUser = async () => {
    const response = await apiPost('users/add', { email, password })
    if (response.success) {
      notify.success('User added successfully')
      onSuccess(response.data._id)
    } else {
      notify.error(response.message || 'Failed to add user')
    }
  }

  return (
    <div>
      <div className="mb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">
          Email address
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="email"
            name="email"
            id="emaidfsdsl"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label htmlFor="password" className="block text-sm font-normal text-gray-700 mb-1">
          Password
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <button
          type="button"
          className=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500  sm:ml-3"
          onClick={handleAddUser}
        >
          Add User
        </button>
        <button
          type="button"
          className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none"
          onClick={() => close()}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default AddUser
