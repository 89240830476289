import moment from 'moment'
import { forwardRef } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate, walletLink } from 'utils'
import { map, join, startCase } from 'lodash'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { IoInformationCircleSharp } from 'react-icons/io5'
// eslint-disable-next-line
const TransactionLogs = forwardRef(({ ...props }, ref) => {
  const columns = [
    {
      name: 'Thawani Invoice ID',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => item?.transactionId,
    },
    {
      name: 'Transaction Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.status === 'completed'
              ? 'bg-green-100 text-green-700'
              : item.status === 'canceled'
              ? 'bg-red-100 text-red-700'
              : item.status === 'initiated'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(item.status)}
        </span>
      ),
    },
    // {
    //   name: 'Car',
    //   width: '10%',
    //   key: 'stripe',
    //   sortable: false,
    //   rendered: item => {
    //     return (
    // <div className="text-xs text-blue-500 font-mono">
    //   {item?.booking ? (
    //     <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/tx/${item?.transactionHash}`} rel="noreferrer">
    //       {item?.booking?.car?.make} {item?.booking?.car?.model} {item?.booking?.rentalCar?.modelYear}
    //     </a>
    //   ) : (
    //     ''
    //   )}
    // </div>
    //     )
    //   },
    // },
    {
      name: 'Booking',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.booking ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">Car Details:</b>
                    <div className="text-xs font-mono">
                      {item?.booking ? (
                        <>
                          <div>
                            Name:{' '}
                            <a target="_blank" className="text-blue-500" href={`/car/edit/${item?.booking?.rentalCar?._id}/car-profile`} rel="noreferrer">
                              {item?.booking?.car?.make} {item?.booking?.car?.model} {item?.booking?.rentalCar?.modelYear}
                            </a>
                          </div>
                          <div className="mt-1">Status: {item?.booking?.rentalCar?.status}</div>
                          <div className="mt-1">
                            Company:{' '}
                            <a target="_blank" className="text-blue-500" href={`/company/edit/${item?.booking?.rentalCar?.company?._id}/company-profile`} rel="noreferrer">
                              {item?.booking?.rentalCar?.company?.name}
                            </a>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">Booking Details:</b>
                    <div className="text-xs font-mono">
                      {item?.booking ? (
                        <>
                          <div>
                            Status:{' '}
                            <span
                              className={classNames(`text-xs font-medium p-0.5 rounded-md`, {
                                'bg-gray-200 text-black': item?.booking?.status === 'pending',
                                'text-white bg-dark-600': item?.booking?.status === 'pending',
                                'bg-rose-400 !text-white': item?.booking?.status === 'cancelled',
                                'bg-green-500 !text-white': item?.booking?.status === 'completed' || item?.booking?.status === 'confirmed',
                              })}
                            >
                              {startCase(item?.booking?.status)}
                            </span>
                          </div>
                          <div className="mt-1">
                            Start Date: <span className="text-black">{formateDate(moment(item?.booking?.rentalStartDate))}</span>
                          </div>
                          <div className="mt-1">
                            End Date: <span className="text-black">{formateDate(moment(item?.booking?.rentalEndDate))}</span>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <a target="_blank" href={`/bookings?bookingId=${item?.booking?._id}&search=${item?.booking?._id}`} rel="noreferrer">
                View booking
              </a>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    // {
    //   name: 'Booking Status',
    //   width: '10%',
    //   key: 'type',
    //   sortable: false,
    //   rendered: item => (
    //     <span
    //       className={classNames(`text-xs font-medium p-1 rounded-md`, {
    //         'bg-gray-200 text-black': item?.booking?.status === 'pending',
    //         'text-white bg-dark-600': item?.booking?.status === 'pending',
    //         'bg-rose-400 !text-white': item?.booking?.status === 'cancelled',
    //         'bg-green-500 !text-white': item?.booking?.status === 'completed' || item?.booking?.status === 'confirmed',
    //       })}
    //     >
    //       {startCase(item?.booking?.status)}
    //     </span>
    //   ),
    // },
    {
      name: 'User',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.user ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">User Details:</b>
                    <div className="text-xs font-mono">
                      {item?.user ? (
                        <>
                          <div>
                            Name:{' '}
                            <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                              {item?.user?.fullName}
                            </a>
                          </div>
                          <div className="mt-1">
                            Email: <span className="text-black">{item?.user?.email}</span>{' '}
                          </div>
                          <div className="mt-1">
                            Phone: <span className="text-black">{item?.user?.phone}</span>{' '}
                          </div>
                          <div className="mt-1">
                            Nationality: <span className="text-black">{item?.user?.nationality}</span>{' '}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                {item?.user?.fullName || item?.user?.email || item?.user?.phone}
              </a>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: 'Amount',
      width: '10%',
      key: 'amount',
      sortable: false,
      rendered: item => (
        <Tooltip
          color="white"
          overlayClassName="!min-w-[270px] !w-full"
          title={
            <div className="flex flex-col gap-2">
              <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                <b className="mb-1.5 border-b">Payment Breakdown:</b>
                <div className="text-xs font-mono">
                  {item?.booking ? (
                    <>
                      <div className="mt-1 grid grid-cols-2">
                        Car Price: <span className="text-black">{item?.booking?.priceWithoutTax} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Insurance Fee: <span className="text-black">{item?.booking?.insuranceFee} OMR</span>{' '}
                      </div>
                      <div className="mt-1 mb-2 grid grid-cols-2">
                        Vat Tax: <span className="text-black">{item?.booking?.vatTax}</span>{' '}
                      </div>
                      <div className="mt-1 grid border-t pt-1 grid-cols-2">
                        Total Amount: <span className="text-black">{item?.booking?.totalPrice} OMR</span>{' '}
                      </div>
                    </>
                  ) : (
                    'No payment details available'
                  )}
                </div>
              </div>
            </div>
          }
        >
          <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{item.amount} OMR</span>
        </Tooltip>
      ),
    },
    {
      name: 'Payment Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.paymentStatus === 'paid'
              ? 'bg-green-100 text-green-700'
              : item.paymentStatus === 'failure'
              ? 'bg-red-100 text-red-700'
              : item.paymentStatus === 'unpaid'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(item.paymentStatus)}
        </span>
      ),
    },
    {
      name: 'TXT Initiation',
      width: '5%',
      key: 'transactionDate',
      align: 'right',
      sortable: true,
      rendered: item => (
        <div className="text-sm text-gray-500">
          <Tooltip title={formateDate(moment(item.transactionDate))}>{moment(item.transactionDate).fromNow()}</Tooltip>
        </div>
      ),
    },
    {
      name: 'TXT Completion',
      width: '5%',
      key: 'completedAt',
      align: 'right',
      sortable: true,
      rendered: item =>
        item.completedAt && (
          <div className="text-sm text-gray-500">
            <Tooltip title={formateDate(moment(item.completedAt))}>{moment(item.completedAt).fromNow()}</Tooltip>
          </div>
        ),
    },
    {
      name: 'TXT Cancellation',
      width: '5%',
      key: 'completedAt',
      align: 'right',
      sortable: true,
      rendered: item =>
        item.canceledAt && (
          <div className="text-sm text-gray-500 flex gap-1">
            <Tooltip title={formateDate(moment(item.canceledAt))}>{moment(item.canceledAt).fromNow()}</Tooltip>
          </div>
        ),
    },
  ]

  return <WithReports {...props} apiURL="transactions" defaultFileName="Transaction" forwardedRef={ref} columns={columns} activeFilterName="past_7_days" />
})

export default TransactionLogs
