import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import CompanyProfile from 'pages/company/CompanyProfile'
import apiGet from 'lib/network/apiGet'
import { forEach } from 'lodash'

const AddCompany = ({ readOnly, currentUserId }) => {
  const history = useHistory()

  const [isEdit, setIsEdit] = useState(null)
  const [companyData, setCompanyData] = useState({})

  const [loading, setLoading] = useState(false)

  const id = useParams()?.id

  useEffect(() => {
    if (id) {
      setIsEdit(true)
      fetchCompany(id)
    }
  }, [id])

  const fetchCompany = async () => {
    setLoading(true)
    const response = await apiGet(`company/${id}`)

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    const data = response.data

    setCompanyData(data)
    setLoading(false)
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.push('/companies')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Company' : 'Add New Company'}
      breadcrumbs={[
        { name: 'Company List', pathname: '/companies' },
        { name: isEdit ? 'User Details' : 'Add New Users', pathname: '/add/company', current: true },
      ]}
    >
      <div className="flex overflow-auto">
        <Switch>
          <Route key={id} path="/add/company" component={() => <CompanyProfile id={id} isEdit={isEdit} />} />
          <Route
            key={id}
            path="/company/edit/:id/company-profile"
            component={() => <CompanyProfile readOnly={readOnly} refetch={fetchCompany} companyData={companyData} loading={loading} id={id} isEdit={isEdit} />}
          />
        </Switch>
      </div>
    </Layout>
  )
}

export default AddCompany
