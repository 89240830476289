import Layout from 'containers/Layout'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import AddEditRole from './AddEditRole'
import Role from './Role'
import Button from 'components/atoms/Button'

const Roles = () => {
  const [role, setRole] = useState(null)
  const [roleId, setRoleId] = useState(null)

  const fetchRole = async () => {
    const response = await apiGet('roles')
    setRole(response?.data)
  }

  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    fetchMenuItems()
  }, [])

  const fetchMenuItems = async () => {
    const response = await apiGet('menu-items')
    setMenuItems(response?.data)
  }

  useEffect(() => {
    fetchRole()
  }, [])

  const [RoleModal, openRoleModal] = useModal({
    content: <AddEditRole menuItems={menuItems} id={roleId} fetchData={fetchRole} setId={setRoleId} />,
    title: roleId ? 'Edit Role' : 'Add New Role',
    dimensions: {
      height: 'auto',
      maxHeight: 'auto',
    },
  })

  const openModal = () => {
    setRoleId(null)
    openRoleModal()
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button onClick={openModal}>Add Role</Button>
        </div>
      }
      title={'List of Roles'}
    >
      {role && <Role setId={setRoleId} data={role} openModal={openRoleModal} fetch={fetchRole} />}
      <RoleModal height={600} />
    </Layout>
  )
}

export default Roles
