import { Menu, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Fragment } from 'react'
import 'antd/es/tooltip/style/css'
import { useHistory } from 'react-router-dom'

const BottomMenu = ({ workspaces, user, handleLogout, currentWorkspace, openChangePasswordModal }) => {
  const history = useHistory()

  return (
    <Menu as="div" className="absolute mt-1 bottom-0 w-full inline-block text-left">
      <div>
        <Menu.Button className="group w-full bg-white border-t border-gray-100 px-3.5 py-2 text-sm text-left font-medium text-gray-700 focus:outline-none">
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={user.userProfile?.profile} alt="" />
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-gray-600 text-xs font-normal truncate">Admin</span>
                <span className="text-gray-700 text-sm truncate">{user.displayName}</span>
              </span>
            </span>
            <SelectorIcon className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ boxShadow: '0px -5px 4px rgb(0 0 0 / 2%)' }}
          className="z-10 -translate-y-full border-t border-gray-100 -top-2 origin-bottom absolute right-0 left-0 mt-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            <Menu.Item onClick={() => history.push('/profile')}>
              {({ active }) => (
                <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Profile settings</span>
              )}
            </Menu.Item>
            <Menu.Item onClick={() => openChangePasswordModal()}>
              {({ active }) => (
                <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Change Password</span>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item onClick={handleLogout}>
              {({ active }) => <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Logout</span>}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default BottomMenu
