import { useState } from 'react'
import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import { useHistory, useParams } from 'react-router-dom'

const PasswordRest = ({ fetchUser }) => {
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' })
  const [targetRole, setTargetRole] = useState('user')
  const history = useHistory()
  const { id } = useParams()

  const handlePasswordChange = async () => {
    if (!passwords?.password || !passwords?.confirmPassword) {
      notify.error('Passwords and confirmation password are required')
      return
    }
    if (passwords.password !== passwords.confirmPassword) {
      notify.error('Passwords do not match')
      return
    }
    const response = await apiPost(`user/reset-password/${id}`, {
      role: targetRole,
      password: passwords.password,
      confirmPassword: passwords.confirmPassword,
    })

    if (response.success) {
      notify.success('Password Updated successfully')
      close()
      fetchUser()
      history.push(`/users/edit/${response.data._id}/user-profile`)
    } else {
      notify.error(response.message || 'Failed to update password')
    }
  }

  return (
    <div>
      <div className="mb-3">
        <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">
          Reset Password for:
        </label>
        <div className="mt-2">
          <div className="flex items-center justify-between space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="admin"
                checked={targetRole === 'admin'}
                onChange={e => setTargetRole(e.target.value)}
                className="focus:ring-dark-500 h-4 w-4 text-dark-600 border-gray-300"
              />
              <span className="ml-2 text-sm text-gray-700">Dashboard</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="company"
                checked={targetRole === 'company'}
                onChange={e => setTargetRole(e.target.value)}
                className="focus:ring-dark-500 h-4 w-4 text-dark-600 border-gray-300"
              />
              <span className="ml-2 text-sm text-gray-700">Company</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="user"
                checked={targetRole === 'user'}
                onChange={e => setTargetRole(e.target.value)}
                className="focus:ring-dark-500 h-4 w-4 text-dark-600 border-gray-300"
              />
              <span className="ml-2 text-sm text-gray-700">User</span>
            </label>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">
          Password
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="password"
            name="password"
            id="password"
            value={passwords.password}
            onChange={e => setPasswords(prev => ({ ...prev, password: e.target.value }))}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="fullName" className="block text-sm font-normal text-gray-700 mb-1">
          Confirm Password
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="password"
            name="password"
            id="password"
            value={passwords.confirmPassword}
            onChange={e => setPasswords(prev => ({ ...prev, confirmPassword: e.target.value }))}
            className="block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <button
          type="button"
          className=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500  sm:ml-3"
          onClick={handlePasswordChange}
        >
          Update Password
        </button>

        <button
          type="button"
          className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none"
          onClick={() => close()}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default PasswordRest
