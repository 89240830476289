import moment from 'moment'
import { forwardRef, Fragment, useEffect, useState } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate, walletLink } from 'utils'
import { map, join, startCase } from 'lodash'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { IoInformationCircleSharp } from 'react-icons/io5'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import apiGet from 'lib/network/apiGet'
import queryString from 'query-string'

// eslint-disable-next-line
const Bookings = forwardRef(({ setSearchKey, ...props }, ref) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [bookingsLogs, setBookingLogs] = useState([])

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  const fetchBookingLogs = async () => {
    const response = await apiGet(`/bookings/${selectedBooking?._id}/logs`)

    if (response?.success) {
      openDrawer()
      setBookingLogs(response?.data)
    }
  }

  useEffect(() => {
    const qs = queryString.parse(window.location.search)

    if (qs?.bookingId) {
      setSelectedBooking({ _id: qs?.bookingId })
    }
  }, [])

  useEffect(() => {
    if (selectedBooking) {
      fetchBookingLogs()
    }
  }, [selectedBooking])
  const columns = [
    {
      name: 'User',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <div className="text-xs text-blue-500 font-mono">
          {item?.user ? (
            <Tooltip
              color="white"
              overlayClassName="!min-w-[400px] !w-full"
              title={
                <div className="flex flex-col gap-2 p-2">
                  <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                    <b className="mb-1.5 border-b">User Details:</b>
                    <div className="text-xs font-mono">
                      {item?.user ? (
                        <>
                          <div>
                            Name:{' '}
                            <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                              {item?.user?.fullName}
                            </a>
                          </div>
                          <div className="mt-1">
                            Email: <span className="text-black">{item?.user?.email}</span>{' '}
                          </div>
                          <div className="mt-1">
                            Phone: <span className="text-black">{item?.user?.phone}</span>{' '}
                          </div>
                          <div className="mt-1">
                            Nationality: <span className="text-black">{item?.user?.nationality}</span>{' '}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <a target="_blank" className="text-blue-500" href={`/users/edit/${item?.user?._id}`} rel="noreferrer">
                {item?.user?.fullName || item?.user?.email || item?.user?.phone}
              </a>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: 'Car',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => {
        return (
          <div className="text-xs text-blue-500 font-mono">
            {item ? (
              <a target="_blank" href={`/car/edit/${item?.rentalCar?._id}/car-profile`} rel="noreferrer">
                {item?.car?.make} {item?.car?.model} {item?.rentalCar?.modelYear}
              </a>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      name: 'Company',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => {
        return (
          <div className="text-xs text-blue-500 font-mono">
            {item?.rentalCar?.company ? (
              <a target="_blank" href={`/company/edit/${item?.rentalCar?.company?._id}/company-profile`} rel="noreferrer">
                {item?.rentalCar?.company?.name}
              </a>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      name: 'Booking Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={classNames(`text-xs font-medium p-1 rounded-md`, {
            'bg-gray-200 text-black': item?.status === 'pending',
            'text-white bg-dark-600': item?.status === 'rejected',
            'bg-rose-400 !text-white': item?.status === 'cancelled',
            'bg-green-500 !text-white': item?.status === 'completed' || item?.status === 'confirmed',
          })}
        >
          {startCase(item?.status)}
        </span>
      ),
    },
    {
      name: 'Amount',
      width: '10%',
      key: 'amount',
      sortable: false,
      rendered: item => (
        <Tooltip
          color="white"
          overlayClassName="!min-w-[270px] !w-full"
          title={
            <div className="flex flex-col gap-2">
              <div className="flex border rounded p-2 flex-col text-[12px] text-gray-500">
                <b className="mb-1.5 border-b">Payment Breakdown:</b>
                <div className="text-xs font-mono">
                  {item ? (
                    <>
                      <div className="mt-1 grid grid-cols-2">
                        Car Price: <span className="text-black">{item?.priceWithoutTax} OMR</span>{' '}
                      </div>
                      <div className="mt-1 grid grid-cols-2">
                        Insurance Fee: <span className="text-black">{item?.insuranceFee} OMR</span>{' '}
                      </div>
                      <div className="mt-1 mb-2 grid grid-cols-2">
                        Vat Tax: <span className="text-black">{item?.vatTax}</span>{' '}
                      </div>
                      <div className="mt-1 grid border-t pt-1 grid-cols-2">
                        Total Amount: <span className="text-black">{item?.totalPrice} OMR</span>{' '}
                      </div>
                    </>
                  ) : (
                    'No payment details available'
                  )}
                </div>
              </div>
            </div>
          }
        >
          <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{item.totalPrice} OMR</span>
        </Tooltip>
      ),
    },
    {
      name: 'Payment Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.paymentStatus === 'paid'
              ? 'bg-green-100 text-green-700'
              : item.paymentStatus === 'failure'
              ? 'bg-red-100 text-red-700'
              : item.paymentStatus === 'unpaid'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {startCase(item.paymentStatus)}
        </span>
      ),
    },
    {
      name: 'Booking Date',
      width: '5%',
      key: 'bookingDate',
      align: 'left',
      sortable: true,
      rendered: item => (
        <div className="text-sm text-gray-500">
          <Tooltip title={formateDate(moment(item.bookingDate))}>{moment(item.bookingDate).fromNow()}</Tooltip>
        </div>
      ),
    },
    {
      name: 'Booking Start Date',
      width: '5%',
      key: 'completedAt',
      align: 'left',
      sortable: true,
      rendered: item =>
        item.rentalStartDate && (
          <div className="text-sm text-gray-500">
            <Tooltip title={formateDate(moment(item.rentalStartDate))}>{moment(item.rentalStartDate).fromNow()}</Tooltip>
          </div>
        ),
    },
    {
      name: 'Booking Start Date',
      width: '5%',
      key: 'completedAt',
      align: 'left',
      sortable: true,
      rendered: item =>
        item.rentalEndDate && (
          <div className="text-sm text-gray-500 flex gap-1">
            <Tooltip title={formateDate(moment(item.rentalEndDate))}>{moment(item.rentalEndDate).fromNow()}</Tooltip>
          </div>
        ),
    },
    {
      name: 'Action',
      width: '5%',
      key: 'completedAt',
      align: 'left',
      rendered: item => (
        <button
          onClick={() => {
            setSelectedBooking(item)
          }}
          className="w-full flex justify-center py-0 px-1 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
          type="submit"
        >
          View logs
        </button>
      ),
    },
  ]

  return (
    <>
      <WithReports {...props} setSearchKey={setSearchKey} apiURL="bookings" defaultFileName="Transaction" forwardedRef={ref} columns={columns} activeFilterName="past_7_days" />
      <Transition.Root show={isDrawerOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={closeDrawer}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={closeDrawer}
                      >
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl">
                    <div className="px-4 sm:px-6 border-b pb-3">
                      <Dialog.Title className="text-lg font-medium text-gray-900">{selectedBooking?.user?.fullName} Booking Logs</Dialog.Title>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 overflow-y-scroll sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div className="h-ful" aria-hidden="true">
                          <div className="container">
                            <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
                              {bookingsLogs?.map((log, index) => {
                                return (index + 1) % 2 !== 0 ? (
                                  <div className="flex flex-row-reverse md:contents" key={log?._id}>
                                    <div className=" col-start-1 col-end-5 p-- rounded-xl my-4 ml-auto">
                                      <div className="">
                                        <h3 className="font-semibold text-gray-500 text-sm mb-1">{log?.updatedBy?.fullName}</h3>
                                        <p className="leading-tight text-gray-500">{moment(log.createdAt).fromNow()}</p>
                                      </div>

                                      <div className="text-gray-500">
                                        Booking Status:{' '}
                                        <span
                                          className={classNames(`text-xs font-medium p-1 rounded-md`, {
                                            'bg-gray-200 text-black': log?.status === 'pending',
                                            'text-white bg-dark-600': log?.status === 'rejected',
                                            'bg-rose-400 !text-white': log?.status === 'cancelled',
                                            'bg-green-500 !text-white': log?.status === 'completed' || log?.booking?.status === 'confirmed',
                                          })}
                                        >
                                          {startCase(log?.status)}
                                        </span>
                                      </div>

                                      <div className="text-gray-500 mt-1">
                                        Payment Status:{' '}
                                        <span
                                          className={`text-xs font-regular ${
                                            log.paymentStatus === 'paid'
                                              ? 'bg-green-100 text-green-700'
                                              : log.paymentStatus === 'failure'
                                              ? 'bg-red-100 text-red-700'
                                              : log.paymentStatus === 'unpaid'
                                              ? 'bg-yellow-100 text-yellow-800'
                                              : 'bg-gray-200'
                                          } px-2 py-1 rounded-md`}
                                        >
                                          {startCase(log.paymentStatus)}
                                        </span>
                                      </div>

                                      <p className="leading-tight text-gray-500">{log?.message}</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                                      <div className="h-full w-6 flex items-center justify-center">
                                        <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                      </div>
                                      <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                                      <div className="h-full w-6 flex items-center justify-center">
                                        <div className="h-full w-1 bg-blue-800 pointer-events-none"></div>
                                      </div>
                                      <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                                    </div>
                                    <div className="col-start-6 col-end-10 p-0 rounded-xl my-4 mr-auto">
                                      <div className="">
                                        <h3 className="font-semibold text-gray-500 text-sm mb-1">{log?.updatedBy?.fullName}</h3>
                                        <p className="leading-tight text-gray-500">{moment(log.createdAt).fromNow()}</p>
                                      </div>

                                      <div className="text-gray-500">
                                        Booking Status:{' '}
                                        <span
                                          className={classNames(`text-xs font-medium p-1 rounded-md`, {
                                            'bg-gray-200 text-black': log?.status === 'pending',
                                            'text-white bg-dark-600': log?.status === 'rejected',
                                            'bg-rose-400 !text-white': log?.status === 'cancelled',
                                            'bg-green-500 !text-white': log?.status === 'completed' || log?.status === 'confirmed',
                                          })}
                                        >
                                          {startCase(log?.status)}
                                        </span>
                                      </div>

                                      <div className="text-gray-500 mt-1">
                                        Payment Status:{' '}
                                        <span
                                          className={`text-xs font-regular ${
                                            log.paymentStatus === 'paid'
                                              ? 'bg-green-100 text-green-700'
                                              : log.paymentStatus === 'failure'
                                              ? 'bg-red-100 text-red-700'
                                              : log.paymentStatus === 'unpaid'
                                              ? 'bg-yellow-100 text-yellow-800'
                                              : 'bg-gray-200'
                                          } px-2 py-1 rounded-md`}
                                        >
                                          {startCase(log.paymentStatus)}
                                        </span>
                                      </div>
                                      <p className="leading-tight text-gray-500">{log?.message}</p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
})

export default Bookings
