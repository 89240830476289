import { useEffect, useRef, useState } from 'react'
import startCase from 'lodash/startCase'
import Layout from 'containers/Layout'
import moment from 'moment'
import _, { get, orderBy } from 'lodash'
import apiGet from 'lib/network/apiGet'
import { useHistory, useParams } from 'react-router-dom'

import Blockies from 'react-blockies'

import Selection from 'components/molecules/Selection'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import { accessibleOnClick } from 'utils'
import { useSelector } from 'react-redux'

import useModal from 'hooks/useModal'

import AddUser from './modals/AddUserModal'
import { IoSearch } from 'react-icons/io5'
import { setUser } from 'state/redux/actions/user.actions'
import Switch from 'components/atoms/Switch'

const Users = () => {
  const history = useHistory()
  const [filter, setFilter] = useState({ type: null, workspace: null })

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [companyNames, setCompanyNames] = useState([])
  const [role, setRole] = useState()
  const scrollPositionRef = useRef(0)

  const { id } = useParams()

  useEffect(() => {
    fetchUsers()
  }, [filter, search])

  const fetchUsers = async (page = 1, role = null, company = null) => {
    if (page === 1) {
      setLoading(true)
    }

    const params = {}
    params.pageNumber = page
    params.pageSize = 20
    params.query = search || undefined

    if (filter.role || role) params.roleId = role || filter.role

    if (filter.company || company) params.companyId = company || filter.company
    if (filter.softDeletedUsers) params.softDeletedUsers = filter?.softDeletedUsers
    const response = await apiGet(`users`, params)

    setUsers(page === 1 ? response.data.user : [...users, ...response.data.user])
    setPagination(response?.data?.pagination || [])

    setLoading(false)
  }

  useEffect(() => {
    fetchCompanies()
    fetchRole()
  }, [])

  const fetchCompanies = async () => {
    const response = await apiGet('companies')
    if (response?.data) {
      const companies = response.data.company.map(company => ({
        id: company._id,
        name: company.name,
      }))
      setCompanyNames(companies)
    } else {
      setCompanyNames([])
    }
  }

  const fetchRole = async () => {
    const response = await apiGet('roles')
    if (response?.data) {
      const roles = _.map(response.data, role => ({
        id: role._id,
        name: role.name,
      }))
      setRole(roles)
    } else {
      setRole([])
    }
  }

  const handleUserTypeChange = selectedOption => {
    setFilter(prevFilter => ({
      ...prevFilter,
      role: prevFilter.role === selectedOption?.id ? null : selectedOption?.id,
    }))
  }

  const handleCompanyTypeChange = selectedOption => {
    setFilter(prevFilter => ({
      ...prevFilter,
      company: prevFilter.company === selectedOption?.id ? null : selectedOption?.id,
    }))
  }

  const user = useSelector(state => get(state, 'user.user'))

  const handleAddUserSuccess = userId => {
    closeModal()
    history.push(`/users/edit/${userId}`)
    fetchUsers()
  }

  const [AddUserModal, openAddUserModel, closeModal] = useModal({
    content: <AddUser onSuccess={handleAddUserSuccess} />,
    title: 'Add User',
    dimensions: {
      height: '250px',
      width: '450px',
    },
  })
  const columns = [
    {
      name: 'Name',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div className="flex items-center cursor-pointer" {...accessibleOnClick(() => (window.location.href = `/users/edit/${item._id}`))}>
            <div className="flex-shrink-0 h-10 w-10 relative">
              {item?.avatar ? (
                <img className="h-10 w-10 rounded-full" src={item?.avatar} alt="" />
              ) : (
                <Blockies className="rounded-full" seed={item.walletAddress || item.email || ''} size={6.5} scale={6} />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm text-normal text-gray-900">{item?.fullName || 'User'}</div>
            </div>
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Email',
      key: 'email',
      sortable: true,
      rendered: item => {
        const userEmail = item.email

        return (
          <>
            <div>
              <span className="text-[13px]">{userEmail}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Phone',
      key: 'phone',
      sortable: true,
      rendered: item => {
        const userPhone = item.phone
        return (
          <>
            <div>
              <span className="text-[13px]">{userPhone}</span>
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Type',
      key: 'type_wa',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]">
          {
            // eslint-disable-next-line
            orderBy(item?.roles?.filter(d => d.name !== 'platform_admin')?.map(r => startCase(r.name)))?.map(d => (
              <span className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5" key={d}>
                {d}
              </span>
            ))
          }
        </span>
      ),
    },
    {
      width: '5%',
      name: 'Company Name',
      key: 'company',
      sortable: true,
      rendered: item => {
        if (item.company && item.company.length > 0) {
          return <span className=" text-[13px]">{item.company[0].name}</span>
        }
      },
    },
    {
      width: '5%',
      name: 'Created',
      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
  ]

  return (
    <Layout
      showLogo={!!id}
      breadcrumbs={[{ name: 'Users', pathname: '/users' }]}
      headerRight={
        <div className="flex items-center justify-end ">
          <div className="flex items-center gap-3 mr-3">
            <p className="whitespace-nowrap">Soft Deleted</p>{' '}
            <Switch
              value={filter.softDeletedUsers}
              onChange={e => {
                setFilter(prevFilter => ({
                  ...prevFilter,
                  softDeletedUsers: e,
                }))
              }}
            />
          </div>
          <Selection
            value={filter.company}
            onChange={e => {
              setUsers([])
              handleCompanyTypeChange(e)
            }}
            className="min-w-[200px] mr-4"
            list={companyNames}
            title="Company Filter"
          />
          <Selection
            value={filter.role}
            onChange={e => {
              setUsers([])
              handleUserTypeChange(e)
            }}
            className="min-w-[200px] mr-4"
            list={role}
            title="User Filter"
          />
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
              <IoSearch className="mr-3 h-4  text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              id="search"
              className="block  pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
              placeholder="Search options"
            />
          </div>
          <Button onClick={openAddUserModel}>Add User</Button>
        </div>
      }
      title="Users"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {users?.length === 0 ? (
                      <EmptyState v2 description="No users found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPages}
                        fetchData={() => fetchUsers(pagination?.currentPage + 1)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setUsers([])
                        }}
                        data={users}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <AddUserModal />
    </Layout>
  )
}

export default Users
