// import { HomeIcon } from "@heroicons/react/solid";
// import classNames from "classnames";
// import { useHistory } from "react-router-dom";

import { MenuAlt2Icon } from '@heroicons/react/outline'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { get } from 'lodash'
import { parseCookies } from 'nookies'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawerToggle } from 'state/redux/actions/settings.actions'

const Header = ({ title = 'Admin Panel', toolbar, showLogo, headerRight, pages = [] }) => {
  const drawer = useSelector(state => get(state, 'settings.drawer'))
  const dispatch = useDispatch()
  const { scanner } = parseCookies()

  const scannerInterface = Boolean(scanner === 'true')

  const openDrawer = () => {
    dispatch(setDrawerToggle(!drawer))
  }

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))
  const user = useSelector(state => get(state, 'user.user'))

  useDocumentTitle(` ${title} | Ajarlee`)

  return (
    <>
      <div className="border-b relative top-0 md:h-[54px] bg-white border-gray-200 px-4 py-0 sm:flex items-center sm:justify-between sm:px-6 lg:px-6">
        <div className="w-full flex sm:max-w-[20rem] md:max-w-full">
          {!drawer && !(user.scanner && scannerInterface) && (
            <button onClick={openDrawer} className="mr-3">
              <MenuAlt2Icon className="h-5 w-5" aria-hidden="true" />
            </button>
          )}
          <div className="flex items-center">
            {showLogo ? <img src={currentWorkspace?.logo} className="h-[30px] mr-4" alt="" /> : null}
            <h1 className="flex-1 text-sm text-gray-900 font-medium mb-0 flex-nowrap">{title}</h1>
          </div>
        </div>
        <div className="w-full flex items-center justify-end">{headerRight}</div>
      </div>
    </>
  )
}

export default Header
