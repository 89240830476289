import PropTypes from 'prop-types'
import colors from 'styles/colors'
import { useField } from 'formik'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { upperFirst } from 'lodash'
import classNames from 'classnames'

// const InputContainer = styled.div`
//   width: 300px;
//   border-radius: 4px;
//   overflow: hidden;
//   margin-top: 12px;

//   input {
//     background: transparent !important;
//     border: 0;
//     padding: 1rem;
//     width: 100%;
//     &:focus {
//       outline: 0;
//     }
//   }
//   .error {
//     height: 24px;
//     width: 24px;
//     svg {
//       font-size: 24px;
//       color: #f44336;
//     }
//   }
// `;

const Input = ({
  maxWidth,
  background = colors.inputBg,
  extraClassName,
  inputClassName,
  prefix,
  color = colors.darkText,
  placeholder,
  type = 'text',
  className,

  name,
  style,
  ...props
}) => {
  const [field, meta] = useField(name)

  const formattedValue = type === 'date' && field.value ? field.value.substring(0, 10) : field.value || ''

  return (
    <div className={classNames(maxWidth, extraClassName)}>
      {style === 'column' && (
        <label htmlFor="email" className="block text-sm text-normal text-gray-700">
          Email
        </label>
      )}

      <div className="mt-1 relative rounded-md shadow-sm">
        {prefix && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
        )}
        <input
          value={formattedValue}
          onChange={field.onChange}
          type={type}
          name={name}
          id={name}
          className={classNames(
            prefix && 'pl-7',
            `block w-full pr-2 focus:outline-none ${meta.error ? 'focus:ring-red-500 border-red-300 text-red-900 placeholder-red-300' : ''} sm:text-sm rounded-md border-gray-300`,
            inputClassName
          )}
          placeholder={placeholder}
          {...props}
        />
        {meta.error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {meta.error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {upperFirst(meta.error)}
        </p>
      )}
    </div>
  )
}

Input.propTypes = {
  background: PropTypes.any,
  className: PropTypes.any,
  color: PropTypes.any,
  inputProps: PropTypes.any,
  name: PropTypes.any.isRequired,
  placeholder: PropTypes.any,
  type: PropTypes.string,
}

export default Input
